import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Página no encontrada" />
    <section className="container mx-auto my-32 text-center">
      <h1 className="text-3xl font-bold">Página no encontrada</h1>
    </section>
  </Layout>
)

export default NotFoundPage
